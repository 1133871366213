import cloneDeep from 'lodash-es/cloneDeep';
import { RESTAURANT_OPTIONS_VALUES } from './config.type';
import { APP_WEB_ORDER_CHANNELS, RESTAURANT_STATE } from './enum';
import { AppRestaurantPriceLevels } from './restaurant-sales-channels.model';
import { RestaurantFiscalPaymentMethodManagerAuthorization } from '@gap/api-client/models';

export interface IAppRestaurantBaseInfo {
	id: string;
	name: string;
	address: string;
	city: string;
	country: string;
	remote_identifications: RestaurantRemoteIdentifications;
}

export interface IAppRestaurantInfo extends IAppRestaurantBaseInfo {
	opening_times?: RestaurantOpeningTimes;
	state?: RESTAURANT_STATE;
	features: RESTAURANT_OPTIONS_VALUES[];
	vat_group: number;
	tags: Array<RestaurantTagInfo>;
	dtt_group_primary?: number;
	dtt_group_secondary?: number;
	contact: ContactInfo;
	publication: boolean;
	fiscal: FiscalInfo;
	legal?: LegalInfo;
	platform_id?: number;
	longitude?: number;
	latitude?: number;
	zelty?: ZeltyInfo;
	// whether the information has been fully loaded via the specific GEt endpoint
	fullyLoaded?: boolean;
	address_extra: string;
	zip_code: number;
}

export class AppRestaurantInfoModel implements IAppRestaurantInfo {
	opening_times?: RestaurantOpeningTimes;
	id: string;
	name: string;
	address: string;
	city: string;
	country: string;
	remote_identifications: RestaurantRemoteIdentifications;
	state?: RESTAURANT_STATE;
	features: RESTAURANT_OPTIONS_VALUES[];
	vat_group: number;
	tags: Array<RestaurantTagInfo>;
	dtt_group_primary?: number;
	dtt_group_secondary?: number;
	contact: ContactInfo;
	publication: boolean;
	fiscal: FiscalInfo;
	legal?: LegalInfo;
	platform_id?: number;
	longitude?: number;
	latitude?: number;
	zelty?: ZeltyInfo;
	price_levels: AppRestaurantPriceLevels;
	webOrderChannels: APP_WEB_ORDER_CHANNELS[];
	fullyLoaded?: boolean;
	address_extra: string;
	zip_code: number;

	constructor(init?: Partial<AppRestaurantInfoModel>) {
		Object.assign(this, cloneDeep(init));
	}
}

export class AppRestaurantBasicInfoModel implements IAppRestaurantBaseInfo {
	id: string;
	name: string;
	address: string;
	city: string;
	country: string;
	remote_identifications: RestaurantRemoteIdentifications;

	constructor(init?: Partial<AppRestaurantBasicInfoModel>) {
		Object.assign(this, cloneDeep(init));
	}
}

export type RestaurantOpeningTimes = {
	monday?: RestaurantOpeningTime;
	tuesday?: RestaurantOpeningTime;
	wednesday?: RestaurantOpeningTime;
	thursday?: RestaurantOpeningTime;
	friday?: RestaurantOpeningTime;
	saturday?: RestaurantOpeningTime;
	sunday?: RestaurantOpeningTime;
};

export type RestaurantOpeningTime = {
	from: string;
	to: string;
};

export type RestaurantRemoteIdentifications = {
	bknb?: string;
	frnb?: string;
};

export type ContactInfo = {
	name?: string;
	email?: string;
	telephone?: string;
	mobile?: string;
};

export type FiscalInfo = {
	tpe_id_merchant?: string;
	nepting_url?: string;
	payment_methods?: Array<RestaurantFiscalPaymentMethodManagerAuthorization>;
	closure_payment_method?: number;
};

export type LegalInfo = {
	owner: number;
	name_extra?: string;
	establishment_id?: string;
};

export type RestaurantTagInfo = {
	name: string;
	description: string;
	id: number;
};

export type ZeltyInfo = {
	keys: ZeltyInfoKeys;
};

export type ZeltyInfoKeys = {
	synchronization: string;
	kiosk: string;
};
