import { createAction, props } from '@ngrx/store';
import { DiscountModel } from "@libs/gap/models";

export const LoadDiscounts = createAction('[DiscountManagement] Loading discounts');

export const LoadDiscountsSuccess = createAction('[DiscountManagement] Loading discounts success', props<{ data: Partial<DiscountModel>[] }>());

export const LoadSpecificDiscount = createAction('[DiscountManagement] Loading specific discount', props<{ id: number }>());

export const LoadSpecificDiscountSuccess = createAction(
	'[OptionsManagement] Loading specific discount success',
	props<{
		id: number;
		data: DiscountModel;
	}>()
);

export const UpdateDiscount = createAction('[DiscountManagement] Update discount', props<{ data: any }>());

export const UpdateDiscountSuccess = createAction('[DiscountManagement] Update discount success', props<{ data: any }>());

export const DeleteDiscount = createAction('[DiscountManagement] Delete discount', props<{ id: number }>());

export const DeleteDiscountSuccess = createAction('[DiscountManagement] Deleting discount success', props<{ id: number }>());


export const RequestFailure = createAction('[DiscountManagement] Handle request error');
