export type SnackBarType = 'success' | 'warning' | 'error' | 'info';
export const SnackBarValues = {
	success: 'success' as SnackBarType,
	info: 'info' as SnackBarType,
	warning: 'warning' as SnackBarType,
	error: 'error' as SnackBarType
}

export type SnackBarDesignType = 'default' | 'management';
export const SnackBarDesign = {
	default: 'default' as SnackBarDesignType,
	management: 'management' as SnackBarDesignType
}