export const BIG_DATA = 'bigData';
export const AVAILABLE = 'availability';
export const RAPBE = 'rap';
export const CLERK_MASTER = 'clerkmaster';
export const CLERK = 'clerk';
export const RESTO_SETTINGS = 'restoSettings';
export const BE = 'be';
export const RESTO_CONFIG = 'restoConfig';
export const IMAGE_STORAGE = 'imageStorage';
export const CONFIG_GLOBAL = 'configurationGlobal';

export const LOCAL_MANAGER = 'localManager';

export const DEVICE = 'device';

export const DELAY_500 = 500;
export const DELAY_1000 = 1000;
export const DELAY_2000 = 2000;
export const DELAY_5000 = 5000;
export const DELAY_10000 = 10000;
export const DELAY_1H = 3600000;

export const DEFAULT_MODE_VALUE = 'N/A';
export const MAX_ERROR_STATE_VALUES = 10;

export const MDM_ORDER = 'mdmorder';
export const CSI_ORDER = 'csiorder';
export const ORDER_UUID = 'orderUuid';

export const MILISECONDS_IN_SECOND = 1000;
export const SECONDS_IN_MINUTE = 60;

export const DEFAULT_CSV_DELIMETER = ';';
