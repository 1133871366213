<div
	class="text-baseLayer-primary flex h-16 cursor-pointer select-none items-center justify-center border-none px-2 opacity-75 transition-all duration-300 ease-in-out will-change-auto"
	[ngClass]="{ 'justify-between': isExpanded(), 'flex-col': !isExpanded() }"
	(click)="toggleMenu()"
>
	<div class="flex items-center justify-center" [ngClass]="{ 'w-full': !isExpanded() }">
		@if (isExpanded()) {
			<div class="logo-app h-10 w-48"></div>
		} @else {
			<div class="logo-app-shrink mb-1 h-3 w-12"></div>
		}
	</div>
	<span class="material-icons" [ngClass]="{ 'mt-1': !isExpanded() }">menu</span>
</div>

<div class="layout-scrollbar mt-10 flex grow scroll-pr-6 flex-col justify-between overflow-y-auto overflow-x-hidden px-2 pb-3 pt-0">
	<div class="flex grow flex-col">
		@for (menuItem of menuItems(); track $index) {
			@if (menuItem.submenu?.length > 0) {
				<div
					class="group relative flex h-16 w-12 cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap rounded-full px-2 will-change-auto"
					[ngClass]="{
						'w-56 justify-between': isExpanded(),
						'justify-center': !isExpanded(),
						'bg-secondary-95': isExpanded() && (isSubmenuActive(menuItem) || rla.isActive)
					}"
					routerLinkActive
					#rla="routerLinkActive"
					[routerLink]="[menuItem.url]"
					(click)="onMenuItemClick(menuItem)"
				>
					<div class="flex items-center">
						<div
							class="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full"
							[ngClass]="{
								'bg-baseLayer-secondary group-hover:opacity-80': isSubmenuActive(menuItem) || rla.isActive,
								'group-hover:bg-secondary-95': !(isSubmenuActive(menuItem) || rla.isActive)
							}"
						>
							<span
								class="material-symbols-outlined"
								[ngClass]="{
									'text-baseLayer-default': isSubmenuActive(menuItem) || rla.isActive,
									'text-baseLayer-primary opacity-75': !(isSubmenuActive(menuItem) || rla.isActive)
								}"
							>
								{{ menuItem.icon }}
							</span>
						</div>
						<p
							class="menu-item-text ml-3 overflow-hidden whitespace-nowrap text-sm font-medium leading-tight tracking-tight"
							[ngClass]="{
								'w-auto': isExpanded(),
								hidden: !isExpanded(),
								'text-baseLayer-secondary': isSubmenuActive(menuItem) || rla.isActive,
								'text-baseLayer-primary opacity-75': !(isSubmenuActive(menuItem) || rla.isActive)
							}"
						>
							{{ menuItem.text | translate }}
						</p>
					</div>
					<span
						*ngIf="isExpanded()"
						class="material-symbols-outlined text-baseLayer-secondary mr-1 transition-transform duration-300 will-change-auto"
						[ngClass]="{ '-rotate-180': menuItem.isSubmenuOpen, hidden: !isSubmenuActive(menuItem) && !rla.isActive }"
					>
						arrow_drop_up
					</span>
				</div>
				<div
					*ngIf="isExpanded()"
					class="overflow-hidden transition-all duration-300 ease-in-out will-change-auto"
					[ngClass]="{ 'max-h-0': !menuItem.isSubmenuOpen, 'max-h-[500px]': menuItem.isSubmenuOpen }"
				>
					<div class="mt-2 w-56 rounded-lg pl-[4.5rem]">
						@for (submenuItem of menuItem.submenu; track $index) {
							<a
								class="flex h-12 cursor-pointer appearance-none items-center font-medium no-underline decoration-inherit transition-colors duration-200 will-change-auto"
								[routerLink]="[submenuItem.url]"
								routerLinkActive
								#rla="routerLinkActive"
								[ngClass]="{
									'!text-baseLayer-secondary': rla.isActive,
									'!text-baseLayer-primary opacity-75': !rla.isActive
								}"
								[attr.style]="'color: inherit;'"
							>
								<span
									class="text-sm leading-tight tracking-tight"
									[ngClass]="{ 'text-baseLayer-secondary': rla.isActive, 'text-baseLayer-primary hover:text-secondary-20': !rla.isActive }"
								>
									{{ submenuItem.text | translate }}
								</span>
							</a>
						}
					</div>
				</div>
			} @else {
				<div
					class="group relative flex h-16 w-12 cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap rounded-full px-2 will-change-auto"
					routerLinkActive
					#rla="routerLinkActive"
					[routerLink]="[menuItem.url]"
					(click)="onMenuItemClick(menuItem)"
					[ngClass]="{
						'w-56 justify-start': isExpanded(),
						'justify-center': !isExpanded(),
						'bg-secondary-95': isExpanded() && rla.isActive
					}"
				>
					<div
						class="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full"
						[ngClass]="{
							'bg-baseLayer-secondary group-hover:opacity-80': rla.isActive,
							'group-hover:bg-secondary-95': !rla.isActive
						}"
					>
						<span
							class="material-symbols-outlined font-medium"
							[ngClass]="{
								'text-baseLayer-primary opacity-75': !rla.isActive,
								'text-baseLayer-default': rla.isActive
							}"
							>{{ menuItem.icon }}</span
						>
					</div>
					<p
						class="menu-item-text ml-3 overflow-hidden whitespace-nowrap text-sm font-medium leading-5 tracking-[0.1px]"
						[ngClass]="{
							'w-auto': isExpanded(),
							hidden: !isExpanded(),
							'text-baseLayer-primary opacity-75': !rla.isActive,
							'text-baseLayer-secondary': rla.isActive
						}"
						[translate]="menuItem.text"
					></p>
				</div>
			}
		}
	</div>
	<div class="group flex min-h-14 w-12 items-center px-2" [ngClass]="{ 'w-56': isExpanded() }">
		<div
			class="relative flex cursor-pointer items-center justify-center overflow-hidden whitespace-nowrap rounded-full"
			(click)="onLogoutClick()"
		>
			<div class="hover:bg-secondary-95 group-hover:bg-secondary-95 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full">
				<span class="material-icons text-baseLayer-primary opacity-75"> logout </span>
			</div>
			<p
				class="text-baseLayer-primary ml-3 text-sm font-medium leading-5 tracking-[0.1px] opacity-75"
				[ngClass]="{ hidden: !isExpanded(), block: isExpanded() }"
			>
				Logout
			</p>
		</div>
	</div>
</div>

<div class="ml-2 w-full">
	<div
		class="bg-neutralVariant-90 my-2 h-px"
		[ngClass]="{
			'w-[calc(100%-48px)]': isExpanded(),
			'w-[calc(100%-16px)]': !isExpanded()
		}"
	></div>
</div>

<div
	class="font-sarabun text-baseLayer-primary mt-1 flex min-h-8 px-7 font-medium leading-tight tracking-[0.5px] opacity-75 ![font-size:12px]"
	[ngClass]="{ 'flex-col': !isExpanded(), 'flex-row': isExpanded() }"
>
	<p class="my-0">Reboot&nbsp;</p>
	<p class="my-0">{{ version() }}</p>
</div>
